// import Swiper JS
import { trimEnd } from 'lodash';
import Swiper from 'swiper';

// core version + navigation, pagination modules:
import SwiperCore, { Navigation, Pagination } from 'swiper/core';

// // import Swiper styles
import 'swiper/swiper-bundle.css';

// configure Swiper to use modules
SwiperCore.use([Navigation, Pagination]);

const swiper = new Swiper('.swiper-container', {
    autoHeight: true,
    direction: 'horizontal',
    slidesPerView: 1,
    spaceBetween: 0,
    centeredSlides: true,
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
    },
    preventClicks: true,
    preventClicksPropagation: true,
    watchSlidesProgress: true,
    watchSlidesVisibility: true,

    // INITIAL SLIDE => AFFICHAGE DU RÉSULTAT DU DIAG (commence à 0)
    initialSlide: 1,
});


// SLIDE OVERLAY PAGE BADGES

const swiperBadge = new Swiper('.swiper-container-popup', {
    direction: 'horizontal',
    slidesPerView: 1,
    centeredSlides: true,
    centeredSlidesBounds: true,
    observer: true, 
    observeParents: true,
    // normalizeSlideIndex: true,
    // observeSlideChildren: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    hashNavigation: true,
    breakpoints: {
        980: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        1300: {
            slidesPerView: 3,
            spaceBetween: 40,
        },
    },
    initialSlide: 0,
});